import React from "react";
import axios from "axios";

// axios.defaults.withXSRFToken = true
// axios.defaults.withCredentials = true

export const authConfig = (token) => {
    return {
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        }
    }
}

export const config = {
    headers: {
        "Accept": "application/json"
    }
}

async function getPersonByUniqueKey(uniqueKey){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/person/all' + uniqueKey)
            .then(res => {
                resolve(res.data)
            })
    });
}

async function getPersonByUsername(username){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/person/' + username)
            .then(res => {
                resolve(res.data)
            })
    });
}

async function getPersonByToken(token){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/person', authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    });
}

async function getImagesByUserId(token, userid){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/person/images/' + userid, authConfig(token))
            .then(res => {
                const imagesData = res.data;

                const groupedData = {};
                imagesData.forEach(data => {
                    const type = data.desc_type;
                    if (!groupedData[type]) {
                        groupedData[type] = [];
                    }
                    groupedData[type].push(data);
                });
                resolve(groupedData)
            })
    })
}

async function getWatchlistByUserId(token, userid, signal){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/person/watchlist/' + userid, {...authConfig(token), signal})
            .then(res => {
                resolve(res.data)
            })
    })
}



async function getSuggestionsByUserId(token, userid){
    return new Promise((resolve, reject) => {
        axios.get(
            process.env.REACT_APP_API_URL_PREFIX + '/person/suggestions/' + userid, authConfig(token))
            .then(res => {
                resolve(res.data)
            })
    })
}


async function userLogin(username, password){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + "/login", {username, password}, config)
            .then(res => {
                resolve(res.data);
            }).catch((res) => {
                reject(res.response.data)
        })
    })
}

async function userRegister(data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + "/register", data, config)
            .then(res => {
                resolve(res.data);
            }).catch((res) => {
            reject(res.response.data)
        })
    })
}

async function userLogout(token){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + "/logout", {}, authConfig(token))
            .then(res => {
                resolve(res.data);
            }).catch((res) => {
            reject(res.response.data)
        })
    })
}

async function uploadProfileVisionboardPic(token, data){
    return new Promise((resolve, reject) => {
        axios.post(
            process.env.REACT_APP_API_URL_PREFIX + "/person/images", data, authConfig(token))
            .then(res => {
                resolve(res);
            }).catch((res) => {
            reject(res.response.data)
        })
    })
}

export {userRegister, userLogout, userLogin, getPersonByUniqueKey, getPersonByUsername, getImagesByUserId, getWatchlistByUserId,
    getSuggestionsByUserId, getPersonByToken, uploadProfileVisionboardPic};

